
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import { Button, ProgressNum } from '@/components'
import Counter from '~/components/Counter/Counter.vue'
import { parseProxyToObject } from '@/utils'
import store from '@/store'
import Pagination from 'v-pagination-3';
import Swal from 'sweetalert2'
export default defineComponent({
  name : 'Transactions',
  components:{
    Button,
    Pagination,
    ProgressNum,
    Counter
  },
  computed: {
    ...mapGetters([
      'clientTransactions',
      'clientCreditsPlan'
    ])
  },
  data(){
    return{
      page:1
    }
  },
  methods:{
    async getClientTransactions(){
      await store.dispatch('getClientTransactions',{page:this.page - 1, pageSize:10})
    },
    async openContactForm(){
      store.dispatch('createStatisticsClient',{concept:'MORE_CREDITS_POPUP_OPEN'})
      let vm = this
      Swal.fire({
        title: vm.$t('suite_swal_contact_title'),
        input: 'textarea',
        inputValue: vm.$t('suite_swal_contact_text'),
        icon:'info',
        confirmButtonText:vm.$t('suite_swal_contact_action'),
        showCloseButton: true,
        inputValidator: (value) => {
          if(!value) return vm.$t('suite_swal_credits_error')
          vm.sendCreditsContactForm(value)
          return null
        }
      })
    },
    async sendCreditsContactForm(message: string){
      let vm = this
      await store.dispatch('contactForCreditsPlan',{message:message})
      .then((data) => {
        Swal.fire(
          vm.$t('suite_swal_contact_sent'),
          vm.$t('suite_swal_contact_sent_advert'),
          'success'
        )
      })
      .catch((response) => {
        Swal.fire(
          '',
          vm.$t('suite_swal_contact_sent_error'),
          'error'
        )
      })
    }
  },
  mounted(){
    this.getClientTransactions()
  }
})
